import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
// import MySwiper from '../components/Swiper'
// import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import WebriQForm from '@webriq/gatsby-webriq-form'


class IndexPage extends React.Component {
 
    
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div>
          <p>This is the homepage</p>
          <Link to="/blog">Go to Blog</Link>
          <br />
          <br />
          <h1>Sample Swiper</h1>
          <br />
          <br />
          <br />
          <br />
          <div className="body-content py-5">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4 mt-4">
                  <div className="contact-info">
                    <h2>Contact Info</h2>
                    <ul>
                      <li>
                        <a href="tel:719-574-0082">
                          <i class="fa fa-phone" aria-hidden="true"></i>{' '}
                          719-574-0082
                        </a>
                      </li>
                      <li>
                        <a href="tel:719-213-2356">
                          <i class="fa fa-fax" aria-hidden="true"></i>{' '}
                          719-213-2356
                        </a>
                      </li>
                      <li>
                        <a href="mailto:solidrockagency@yahoo.com">
                          <i class="fa fa-envelope" aria-hidden="true"></i>{' '}
                          solidrockagency@yahoo.com
                        </a>
                      </li>
                      <li>
                        <p>
                          <i class="fa fa-map-marker" aria-hidden="true"></i>{' '}
                          2918 Austin Bluffs Parkway Suite 200 Colorado Springs,
                          CO 80918
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-8 mt-4">
                  <div className="contact-form">
                    <WebriQForm
                      name="Home Contact"
                      method="POST"
                      id="contact"
                      data-form-id="8136dc5e-01bc-468c-9c41-2abf13073983"
                      data-thankyou-url="/thank-you"
                    >
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="Name"
                          aria-describedby="Name"
                          placeholder="Name"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="Email"
                          placeholder="Enter email"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="Subject"
                          placeholder="Subject"
                        />
                      </div>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          id="Message"
                          rows="7"
                          placeholder="Message"
                        ></textarea>
                      </div>
                      <div class="form-group">
                        <div className="webriq-recaptcha mb-3" />
                      </div>
                      <button type="submit" class="btn btn-primary submit">
                        Submit
                      </button>
                    </WebriQForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
